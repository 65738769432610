import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import textFit from 'textfit';

const fit = (element, options = {}) => {
  if (!element) {
    console.error('[TextFit] element not valid');
    return;
  }
  textFit(element, { alignHoriz: true, alignVert: true, multiLine: true, ...options });
};

const VIRTUAL_DOM_READY_TIMEOUT = 10000;
let timeoutStartDate = 0;
const onVirtualDomReady = (element, callback) => {
  const { innerHTML } = element;

  if (innerHTML) {
    callback();
  } else {
    const now = Date.now();
    if (!timeoutStartDate) {
      timeoutStartDate = now;
    } else if (now - timeoutStartDate < VIRTUAL_DOM_READY_TIMEOUT) {
      setTimeout(() => {
        onVirtualDomReady(element, callback);
      }, 10);
    }
  }
};

export const TextFit = ({ className, text, minFontSize, maxFontSize, style }) => {
  const [isFitted, setIsFitted] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      onVirtualDomReady(element, () => {
        fit(element, { minFontSize, maxFontSize });
        setIsFitted(true);
      });
    }
  }, [maxFontSize, minFontSize, textRef]);

  return (
    <div className={className} ref={textRef} style={{ visibility: isFitted ? 'visible' : 'hidden', ...style }}>
      {text}
    </div>
  );
};

TextFit.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  minFontSize: PropTypes.number,
  maxFontSize: PropTypes.number,
};

TextFit.defaultProps = {
  className: '',
  minFontSize: 6,
  maxFontSize: 80,
  style: undefined,
};
