import React from 'react';
import PropTypes from 'prop-types';
import S from './ui.Button.Styled';

export const Button = ({ className, children, ...restProps }) => (
  <S.Button className={className} {...restProps}>
    {children}
  </S.Button>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: '',
};
