import styled from 'styled-components';
import { font } from 'styles/theme';
import tiles from 'assets/images/tiles.svg';

export default {
  ContentNotAvailable: styled.div`
    background: url('${tiles}');
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Logo: styled.img`
    margin: 60px auto;
    width: 140px;
    &:hover {
      transform: translateY(-3px);
    }

    transition: transform 0.25s ease;
  `,
  Icon: styled.img`
    max-width: 240px;
    max-height: 200px;
    margin-bottom: 40px;
  `,
  Text: styled.div`
    margin-bottom: 15px;
    font-size: ${font.sizes.xxxl};
    font-family: ${font.fonts.lato};
    font-weight: ${font.weights.normal};
    text-align: center;
    padding: 0px 5px;
  `,
};
