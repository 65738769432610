import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import favicon from 'assets/images/favicon.ico';

export const Head = ({ title, metadata }) => {
  return (
    <Helmet title={title}>
      <link rel="shortcut icon" type="image/ico" href={favicon} />
      <meta property="og:title" content={metadata?.title} />
      <meta property="og:description" content={metadata?.description} />
      <meta property="og:image" content={metadata?.image} />
      <meta property="og:image:width" content={1200} />
      <meta property="og:image:height" content={630} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="Answered question" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
};

Head.defaultProps = {
  title: null,
  metadata: {
    title: '100mentors',
    description: null,
    image: null,
  },
};
