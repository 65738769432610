import styled from 'styled-components';
import { darken } from 'polished';
import { colors, font } from 'styles/theme';

const Slider = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;

  transition: height 0.25s ease;
`;

export default {
  ResponsiveContainer: styled.div`
    position: relative;
    width: 85%;
    max-width: 500px;

    margin-top: -60px;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.5);
    background: white;
    border-radius: 8%;
    overflow: hidden;
    transform: translateZ(0);

    @media only screen and (min-width: 600px) {
      width: 100%;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }

    @media only screen and (min-width: 1024px) {
      margin-top: 0px;
      margin-left: -30px;
      margin-right: 0px;
    }
  `,
  ResponsiveChild: styled.div`
    padding-top: 100%;
    height: 0px;
  `,
  AnswerVideoPlayer: styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    &:hover {
      ${Slider} {
        height: 20px;
        @media only screen and (min-width: 1024px) {
          height: 10px;
        }
      }
    }
  `,
  Video: styled.video`
    display: block;
    object-fit: cover;
    filter: brightness(${({ isPlaying }) => (isPlaying ? '1' : '0.4')});
    cursor: ${({ isPlaying }) => (isPlaying ? 'pointer' : 'default')};
  `,
  Timer: styled.div`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    position: absolute;
    color: ${colors.hmBlack};
    top: 30px;
    right: 30px;
    font-size: ${font.sizes.s};
    font-weight: ${font.weights.light};
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    background: #ffffffe6;
    padding: 3px;
    border-radius: 3px;
    width: 45px;
    box-sizing: border-box;
    text-align: center;
  `,
  Slider,
  SliderFill: styled.div.attrs(({ currentTime, totalTime }) => ({
    style: {
      width: `${(currentTime / totalTime) * 100}%`,
    },
  }))`
    height: 100%;
    background: ${colors.hmBrandYellow};
    transition: width 0.1s ease;
  `,
  Content: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Header: styled.div`
    flex: 0;
  `,
  Main: styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
  `,
  NoVideoBackground: styled.div`
    background-color: ${colors.hmBlack};
    opacity: 0.3;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
  NoVideoMain: styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    font-weight: 600;
  `,
  Footer: styled.div`
    flex: 0 0 auto;
    display: ${({ isPlaying }) => (isPlaying ? 'none' : 'flex')};
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 30px 30px 30px;
  `,
  PlayButton: styled.button`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    cursor: pointer;
    padding: 13px 15px;
    border: solid 1px ${colors.white};
    border-radius: 20px;
    background-color: #ffffff33;
    transition: background-color 250ms ease;
    color: ${colors.white};
    font-family: ${font.fonts.roboto};
    font-weight: ${font.weights.bold};
    font-size: ${font.sizes.xs};
    text-transform: uppercase;
    min-width: 144px;

    &:hover {
      background-color: ${darken(0.2, '#ffffff33')};
    }
    &:focus {
      outline: none;
    }
  `,
  Mentor: styled.a`
    font-size: ${font.sizes.l};
    font-weight: ${font.weights.bold};
    color: ${colors.white};
    text-decoration: none;
    margin: 0 auto 5px auto;
  `,
  Headline: styled.div`
    font-size: ${font.sizes.m};
    font-weight: ${font.weights.light};
    color: ${colors.white};
    padding: 0px 30px;
  `,
};
