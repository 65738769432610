import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { AnswerPage } from 'pages';

export const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/a/:id" component={AnswerPage} />
      <Route
        render={() => {
          window.location = 'https://www.100mentors.com';
        }}
      />
    </Switch>
  </BrowserRouter>
);
