import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui.Button';
import S from './ui.LinkButton.Styled';

export const LinkButton = ({ className, children, ...restProps }) => (
  <S.LinkButton className={className} {...restProps}>
    <Button>{children}</Button>
  </S.LinkButton>
);

LinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  className: '',
};
