import styled from 'styled-components';
import { colors, font } from 'styles/theme';
import { TextFit } from 'modules/ui';
import tiles from 'assets/images/tiles.svg';

export default {
  AnswerPage: styled.div`
    background-image: url('${tiles}');
    background-color: ${colors.paleGrey};
    display: flex;
    align-items: center;
    min-height: 100%;
    text-align: center;
    width: 100%;
  `,
  Body: styled.div`
    width: 100%;
  `,
  Logo: styled.img`
    margin: 60px auto;
    width: 140px;
    &:hover {
      transform: translateY(-3px);
    }

    transition: transform 0.25s ease;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      margin: 0 10px;
    }
  `,
  QuestionCardContainer: styled.div`
    width: 100%;
    @media only screen and (min-width: 600px) {
      width: auto;
    }
  `,
  QuestionCard: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    max-width: 550px;
    margin: 0 auto;

    border-radius: 8%;
    overflow: hidden;

    @media only screen and (min-width: 600px) {
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
      min-height: 550px;
      width: 550px;
    }
  `,
  QuestionContainer: styled.div`
    @media only screen and (min-width: 600px) {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,
  Quote: styled.img`
    margin-top: 25px;
    width: 100px;
    height: 65px;

    @media only screen and (min-width: 600px) {
      margin: 25px auto 0 auto;
      width: 130px;
      height: 85px;
    }
  `,
  Question: styled.div`
    margin: 0 35px;
    font-size: ${font.sizes.xl};
    line-height: 1.36;
  `,
  Student: styled.div`
    margin-top: 20px;
    margin-bottom: 135px;
    font-size: ${font.sizes.l};
    font-weight: ${font.weights.light};

    @media only screen and (min-width: 600px) {
      margin-top: 20px;
      margin-bottom: 135px;
    }
    @media only screen and (min-width: 1024px) {
      margin: 30px 0;
    }
  `,
  Footer: styled.div`
    margin: 80px 10px;
  `,
  FooterTitle: styled.div`
    margin-bottom: 15px;
    font-size: ${font.sizes.xxxl};
    font-family: ${font.fonts.lato};
    font-weight: ${font.weights.normal};
  `,
  FooterSubtitle: styled.div`
    margin-bottom: 30px;
    font-size: ${font.sizes.xl};
    font-family: ${font.fonts.roboto};
    font-weight: ${font.weights.light};
  `,
  SocialContainer: styled.div`
    display: flex;
    justify-content: space-around;
    max-width: 170px;
    margin: 35px auto;
  `,
  SocialItem: styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: ${colors.hmGrey2};
    color: ${colors.white};
    font-size: ${font.sizes.xl};
    cursor: pointer;

    &:hover {
      background: ${colors.hmBrandPurple};
    }

    transition: all 0.25s ease;
  `,
  TextFit: styled(TextFit)`
    width: 450px;
    height: 300px;
  `,
};
