import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeImage } from 'modules/ui';
import playIcon from 'assets/images/play.svg';
import externalLinkIcon from 'assets/images/external-link-alt.svg';
import S from './answer.VideoPlayer.Styled';

export const AnswerVideoPlayer = ({
  video,
  poster,
  createdById,
  createdByName,
  createdByHeadline,
  createdByIsProfilePrivate,
  duration,
}) => {
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef();

  const play = () => {
    setPlaying(true);
    videoRef.current.play();
  };

  const pause = () => {
    setPlaying(false);
    videoRef.current.pause();
  };

  const seek = (newTime) => {
    videoRef.current.currentTime = newTime;
  };

  const onVideoEnded = () => {
    setPlaying(false);
    seek(0);
  };

  const seekOnClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const SLIDER_WIDTH = videoRef.current.offsetWidth;
    const percentClick = (x / SLIDER_WIDTH) * 100;
    const newCurrentTime = (videoRef.current.duration * percentClick) / 100;
    videoRef.current.currentTime = newCurrentTime;
    seek(newCurrentTime);
    e.stopPropagation();
  };

  const limitedHeadline =
    createdByHeadline?.length > 100 ? `${createdByHeadline.substr(0, 100)}...` : createdByHeadline;

  return (
    <S.ResponsiveContainer>
      <S.ResponsiveChild>
        <S.AnswerVideoPlayer>
          {video && (
            <S.Video
              ref={videoRef}
              onEnded={onVideoEnded}
              isPlaying={isPlaying}
              onTimeUpdate={(event) => setCurrentTime(event.target.currentTime)}
              width="100%"
              height="100%"
              preload="metadata"
              poster={poster}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </S.Video>
          )}
          <S.Content onClick={() => isPlaying && pause()}>
            {video && (
              <>
                <S.Header>
                  <S.Timer visible={duration > 0}>{(duration - currentTime).toFixed(0)}s</S.Timer>
                </S.Header>
                <S.Main>
                  <S.PlayButton onClick={play} hidden={isPlaying}>
                    <FontAwesomeImage src={playIcon} height={12} />
                    &nbsp; {currentTime === 0 ? 'watch answer' : 'resume'}
                  </S.PlayButton>
                </S.Main>
              </>
            )}
            {!video && (
              <>
                <S.NoVideoBackground />
                <S.NoVideoMain>
                  <p>Video is not available.</p>
                </S.NoVideoMain>
              </>
            )}
            <S.Footer isPlaying={isPlaying}>
              {createdByIsProfilePrivate ? (
                <S.Mentor as="div">{createdByName}</S.Mentor>
              ) : (
                <S.Mentor
                  href={`https://platform.100mentors.com/mentor/${createdById}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {createdByName}&nbsp; <FontAwesomeImage src={externalLinkIcon} height={18} />
                </S.Mentor>
              )}
              {!!limitedHeadline && <S.Headline>{limitedHeadline}</S.Headline>}
            </S.Footer>
          </S.Content>
          <S.Slider onClick={seekOnClick}>
            <S.SliderFill currentTime={duration ? currentTime : 0} totalTime={duration || 100} />
          </S.Slider>
        </S.AnswerVideoPlayer>
      </S.ResponsiveChild>
    </S.ResponsiveContainer>
  );
};

AnswerVideoPlayer.propTypes = {
  video: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  createdById: PropTypes.string.isRequired,
  createdByName: PropTypes.string.isRequired,
  createdByHeadline: PropTypes.string,
  createdByIsProfilePrivate: PropTypes.bool.isRequired,
  duration: PropTypes.number,
};

AnswerVideoPlayer.defaultProps = {
  createdByHeadline: '',
  duration: 0,
};
