import React from 'react';
import PropTypes from 'prop-types';
import S from './ui.FontAwesomeImage.Styled';

export const FontAwesomeImage = ({ height, ...restProps }) => {
  return <S.FontAwesomeImage height={height} {...restProps} />;
};

FontAwesomeImage.propTypes = {
  height: PropTypes.number.isRequired,
};
