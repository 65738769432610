import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { Head, useWindowSize } from 'modules/html';
import { LinkButton, ContentNotAvailable, FontAwesomeImage } from 'modules/ui';

import facebookIcon from 'assets/images/facebook-f.svg';
import instagramIcon from 'assets/images/instagram.svg';
import linkedInIcon from 'assets/images/linkedin-in.svg';
import notAvailableIcon from 'assets/images/not-available.svg';
import notFoundIcon from 'assets/images/404.svg';

import logo from 'assets/images/logo.svg';
import quote from 'assets/images/fill-1.svg';
import { AnswerVideoPlayer } from './answer.VideoPlayer';
import S from './answer.Answer.Styled';

const metadataList = [
  {
    title: 'Join role models like you on 100mentors',
    description: 'Answer questions like these in 100-second videos to start making your impact today.',
  },
  {
    title: 'Join a community of 5000+ role models',
    description: 'Answer questions like these using the 100mentors app to impact 22,000+ young people worldwide.',
  },
  {
    title: 'Be part of a community of experts',
    description: 'Join 100mentors to share answers to questions like these and inspire young people worldwide.',
  },
  {
    title: 'What would you answer?',
    description:
      'See how experts using the 100mentors app respond to questions like these in under 100 seconds and inspire 22,000+ young people worldwide.',
  },
];

const query = gql`
  query AnswerSharePublic($id: ID!) {
    answerSharePublic(id: $id) {
      id
      state
      metadata {
        image
      }
      question {
        id
        text
        transcription
        createdByName
      }
      answer {
        id
        video
        photo
        videoDuration
        createdByName
        createdById
        createdByHeadline
        createdByIsProfilePrivate
        state
      }
    }
  }
`;

const QuestionCard = ({ text, creator }) => {
  const size = useWindowSize();
  return (
    <S.QuestionCardContainer>
      <S.QuestionCard>
        <S.QuestionContainer>
          <S.Quote src={quote} />
          <>
            {size.width > 600 && <S.TextFit minFontSize={12} maxFontSize={40} text={text} />}
            {size.width <= 600 && <S.Question>{text}</S.Question>}
          </>
        </S.QuestionContainer>
        <S.Student>{creator}</S.Student>
      </S.QuestionCard>
    </S.QuestionCardContainer>
  );
};

QuestionCard.propTypes = {
  text: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
};

export const AnswerPage = ({ match }) => {
  const { params: { id } = {} } = match || {};
  const { loading, error, data } = useQuery(query, { variables: { id } });
  const randomMetadata = _.sample(metadataList);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (!loading && data && window) {
      window.prerenderReady = true;
    }
  }, [data, loading]);

  // Loading
  if (loading && !data) {
    return null;
  }

  // Not found || Archived
  if (data?.answerSharePublic === null || data?.answerSharePublic?.state === 'ARCHIVED') {
    return (
      <>
        <Head
          title="Answer not found | 100mentors"
          metadata={{
            title: randomMetadata.title,
            description: randomMetadata.description,
          }}
        />
        <ContentNotAvailable
          icon={data.answerSharePublic === null ? notFoundIcon : notAvailableIcon}
          text={data.answerSharePublic === null ? "We couldn't find this Q&A" : undefined}
        />
      </>
    );
  }

  const hasAnswerFailed = data?.answerSharePublic?.answer?.state === 'FAILED';
  if (error || hasAnswerFailed) {
    return (
      <>
        <Head
          title="Temporary Unavailable | 100mentors"
          metadata={{
            title: randomMetadata.title,
            description: randomMetadata.description,
          }}
        />
        <ContentNotAvailable icon={notFoundIcon} text="Page is temporarily unavailable" />
      </>
    );
  }

  const { answerSharePublic } = data || {};
  const { question, answer, metadata } = answerSharePublic || {};
  const questionText = question?.text || question?.transcription;

  return (
    <S.AnswerPage>
      <Head
        title={`${answer?.createdByName}'s answer | 100mentors `}
        metadata={{
          title: randomMetadata.title,
          description: randomMetadata.description,
          image: metadata?.image,
        }}
      />
      <S.Body>
        <a href="https://www.100mentors.com">
          <S.Logo src={logo} />
        </a>
        <S.Content>
          <QuestionCard text={questionText} creator={question?.createdByName} />
          <AnswerVideoPlayer
            video={answer?.video}
            poster={answer?.photo}
            duration={answer?.videoDuration}
            createdById={answer?.createdById}
            createdByName={answer?.createdByName}
            createdByHeadline={answer?.createdByHeadline}
            createdByIsProfilePrivate={answer?.createdByIsProfilePrivate}
          />
        </S.Content>
        <S.Footer>
          <S.FooterTitle>Are you an expert in what you do?</S.FooterTitle>
          <S.FooterSubtitle>Start sharing your expertise and thoughts in 100-second videos, today.</S.FooterSubtitle>
          <LinkButton href="https://www.100mentors.com/mentors" target="_blank" rel="noopener noreferrer">
            become a mentor
          </LinkButton>
          <S.SocialContainer>
            <S.SocialItem href="https://www.facebook.com/100mentors" target="_blank" rel="noopener noreferrer">
              <FontAwesomeImage src={facebookIcon} height={22} />
            </S.SocialItem>
            <S.SocialItem href="https://www.instagram.com/my100mentors" target="_blank" rel="noopener noreferrer">
              <FontAwesomeImage src={instagramIcon} height={22} />
            </S.SocialItem>
            <S.SocialItem href="https://www.linkedin.com/company/100mentors" target="_blank" rel="noopener noreferrer">
              <FontAwesomeImage src={linkedInIcon} height={22} />
            </S.SocialItem>
          </S.SocialContainer>
        </S.Footer>
      </S.Body>
    </S.AnswerPage>
  );
};

AnswerPage.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AnswerPage.defaultProps = {
  match: {},
};

export default AnswerPage;
