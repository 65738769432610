import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import GlobalCSS from 'GlobalCSS';
import ReactGA from 'react-ga4';

import { Router, apolloClient } from 'modules/core';

if (window) {
  window.prerenderReady = false;
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_ID, {
    gtagOptions: {
      anonymize_ip: true,
    },
  });
}

const App = () => {
  return (
    <HelmetProvider>
      <ApolloProvider client={apolloClient}>
        <GlobalCSS />
        <Router />
      </ApolloProvider>
    </HelmetProvider>
  );
};

export default App;
